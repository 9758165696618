export default function HeroSection() {
    return (
        <section id="heroSection" className="hero-section">
            <div className="hero-section-content-box">
                <div className="hero-section-content">
                    <p className="section-title">Hey, I'm Shah Jahan Ashraf</p>
                    <h1 className="hero-section-title">
                        <span className="hero-section-title-color">Full Stack</span>
                        {" "}
                        <br />
                        Developer
                    </h1>
                    <p className="hero-section-description">
                        Aiming to develop scalable Web Apps in MERN Stack,
                        <br />
                        <p className="hero-section-description2">I am actively looking for opportunities in Full Stack Development!</p>
                    </p>
                </div>
                <div className="hero-section-buttons">
                    <a href="https://drive.google.com/file/d/1EY-oU3CpuQl-497EIM5GVs7VcNZVGHfd/view?usp=drive_link" target="_blank">
                        <button className="btn btn-primary">Download Resume</button>
                    </a>
                    <a href="https://www.linkedin.com/in/shah-jahan-ashraf/" target="_blank">
                        <button className="btn btn-outline-primary">Get Connected</button>
                    </a>
                </div>
            </div>
            <div className="hero-section-img">
                <img src="./img/hero_img.png" alt="Hero Section Image" />
            </div>
        </section>
    )

}