import HeroSection from "../HeroSection";
import Skills from "../Skills";
import About from "../About";
import Portfolio from "../Portfolio";
import Testimonials from "../Testimonials";
import Contact from "../Contact";
import Footer from "../Footer";

export default function Home () {
    return (
        <>
            <HeroSection />
            <Skills />
            <About />
            <Portfolio />
            <Testimonials />
            <Contact />
            <Footer />
        </>
    )
}