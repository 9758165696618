import data from "../../data/index.json"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Portfolio() {
    return <section className="portfolio-section" id="Portfolio">
        <div className="portfolio-container-box">
            <div className="portfolio-container">

                <h2 className="section-heading">Portfolio Projects</h2>
            </div>
            <div>
                <a href="https://github.com/shah-jahaan" target="_blank">
                    <button className="btn btn-primary">Visit my GitHub</button>
                </a>
            </div>
        </div>
        <div className="portfolio-section-container">
            {data?.portfolio?.map((item, index) => (

                <div key={index} className="portfolio-section-card">
                    <div className="portfolio-section-img">
                        <img src={item.src} alt="Placeholder" />
                    </div>
                    <div className="portfolio-section-card-content">
                        <div>
                            <h3 className="portfolio-section-title">{item.title}</h3>
                            <p className="text-md">{item.description}</p>
                            <p className="text-sm portfolio-link btn-github">{item.link}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </section>

}