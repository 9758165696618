export default function Contact() {
    return (
        <section id="Contact" className="contact-section">
            <div>
                <h2 className="section-heading">Contact Me</h2>
                <p className="text-lg">
                <b><a href="mailto:shahjahan101.personal@gmail.com">Mail me</a></b> at <b><a href="mailto:shahjahan101.personal@gmail.com">shahjahan101.personal@gmail.com</a></b> or contact here to explore collaboration opportunities!
                </p>
            </div>
            <form className="contact-form-container">
                <div className="container">
                    <label htmlFor="first-name" className="contact-label">
                        <span className="text-md">First Name</span>
                        <input
                            type="text"
                            className="contact-input text-md"
                            name="first-name"
                            id="first-name"
                            required
                        />
                    </label>
                    <label htmlFor="last-name" className="contact-label">
                        <span className="text-md">Last Name</span>
                        <input
                            type="text"
                            className="contact-input text-md"
                            name="last-name"
                            id="last-name"
                            required
                        />
                    </label>
                    <label htmlFor="email" className="contact-label">
                        <span className="text-md">Email</span>
                        <input
                            type="email"
                            className="contact-input text-md"
                            name="email"
                            id="email"
                            required
                        />
                    </label>
                    <label htmlFor="phone-number" className="contact-label">
                        <span className="text-md">Phone No</span>
                        <input
                            type="number"
                            className="contact-input text-md"
                            name="phone-number"
                            id="phone-number"
                            required
                        />
                    </label>
                </div>
                <label htmlFor="choode-topic" className="contact-label">
                    <span className="text-md">Choose a topic</span>
                    <select id="choose-topic" className="contact-input text-md">
                        <option>Select One...</option>
                        <option>Internship</option>
                        <option>Job Offer</option>
                        <option>Project</option>
                    </select>
                </label>
                <label htmlFor="message" className="contact-label">
                    <span className="text-md">Message</span>
                    <textarea
                        className="contact-input text-md"
                        id="message"
                        rows="8"
                        placeholder="Type your message..."
                    />
                </label>
                <div>
                    <button className="btn btn-primary contact-form-btn">Submit</button>
                </div>
            </form>
        </section>
    );
}
