import data from "../../data/index.json"

export default function Testimonial() {
  return (
    <section className="testimonial-section" id="Testimonial">
      <div className="portfolio-container-box">
        <div className="portfolio-container">
          <h2 className="section-heading">Senior's Recommendations</h2>
        </div>
      </div>
      <div className="portfolio-section-container">
        {data?.testimonials?.map((item, index) => (
          <div key={index} className="testimonial-section-card">
            <p className="text-md">{item.description}</p>
            <div className="testimonial-section-card-author-detail">
              <img src={item.src} alt="Avatar" />
              <div>
                <p className="text-md testimonial-author-name">
                  {item.author_name}
                </p>
                <p className="text-md testimonial-author-designation">
                  {item.author_designation}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
