export default function About() {
    return (
        <section id="About" className="about-section">
            <div className="about-section-img">
                <img src="./img/about-me.png" alt="About Me" />
            </div>
            <div className="hero-section-content box about-section-content-box">
                <div className="hero-section-content">
                    <h2 className="section-heading">About Me</h2>
                    <p className="hero-section-description">I am a self motivated Full Stack Developer, having a solid understanding of JavaScript, React JS, Express, Node JS, MongoDB, MySQL and third-part APIs. I compe up with strong OOP concepts, problem solving skills, aesthetic sense for front end and logic building for backend!</p>
                    <p className="hero-section-description">Currently, I am looking for Full Stack Development opportunities to implement my skills in real world projects.</p>
                </div>
            </div>
        </section>
    )
}